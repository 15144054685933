<script lang="ts" setup>
import { useConfigStore } from '@core/stores/config'
import { AppContentLayoutNav } from '@layouts/enums'

const DefaultLayoutWithHorizontalNavLanding = defineAsyncComponent(() => import('./components/DefaultLayoutWithHorizontalNavLanding.vue'))
const DefaultLayoutWithVerticalNavLanding = defineAsyncComponent(() => import('./components/DefaultLayoutWithVerticalNavLanding.vue'))

const configStore = useConfigStore()
const isMobileDevice = useMobileDevice()

watch(() => isMobileDevice, () => {
  if (isMobileDevice) {
    configStore.appContentLayoutNav = AppContentLayoutNav.Vertical
    configStore.footerType = 'static'
  }

  else { configStore.appContentLayoutNav = AppContentLayoutNav.Horizontal }
}, { immediate: true })

const { layoutAttrs, injectSkinClasses } = useSkins()

injectSkinClasses()
</script>

<template>
  <Component
    v-bind="layoutAttrs"
    :is="isMobileDevice ? DefaultLayoutWithVerticalNavLanding : DefaultLayoutWithHorizontalNavLanding"
  >
    <slot />
  </Component>
</template>

<style lang="scss">
// As we are using `layouts` plugin we need its styles to be imported
@use "@layouts/styles/default-landing-layout";
</style>
